<template>
  <div class="container">
    <div class="header">
      <img
        class="header-return"
        src="@/assets/icons/icon_return.png"
        alt=""
        @click="onClickReturn"
      />
      <img class="header-logo" :src="brandImage" alt="" />
      <div style="visibility: hidden;width:33px"></div>
    </div>
    <div class="content">
      <div class="content-title">Forgot password</div>
      <div class="content-step">
        <div
          class="step-item step-one"
          :style="step <= 3 ? 'color:' + themeMainColor +';' : 'color:#A7A9AC'"
        >
          <div class="step-container" :class="step<=3 ? `step-container-active`:``">
            <div class="inner"  :class="step<=31 ? `active`:``">1</div>
          </div>
          <span style="margin-top:10px">Enter email</span>
        </div>
        <div class="step-split"></div>
        <div
          class="step-item step-two"
            :style="step > 1 && step <= 3 ? 'color:' + themeMainColor +';' : 'color:#A7A9AC'"
        >
          <div class="step-container" :class="(step > 1 && step <= 3 ) ? `step-container-active`:``">
            <div class="inner"  :class="(step > 1 && step <= 3 ) ? `active`:``">2</div>
          </div>
          <span style="margin-top:10px">Verification code</span>

        </div>
        <div class="step-split"></div>
        <div
          class="step-item step-three"
            :style="step === 3 ? 'color:' + themeMainColor +';' : 'color:#A7A9AC'"
        >
          <div class="step-container" :class="step===3 ? `step-container-active`:``">
            <div class="inner"  :class="step===3 ? `active`:``">3</div>
          </div>
          <span style="margin-top:10px">Reset password</span>


        </div>
      </div>
      <template v-if="step === 1">
        <div class="step-msg">
          Please enter your registered email address
        </div>
        <input
          ref="email"
          class="email"
          type="text"
          v-model.trim="email"
          placeholder="Email address"
        />
        <button
          class="button-confirm"
          @click="onClickConfirm"
          :style="time > 0 ? 'background:#A7A9AC;' : ''"
          :disabled="time > 0 ? true : false"
        >
          Confirm
          <div v-show="time > 0">&nbsp;({{ time }}s)</div>
        </button>
      </template>
      <template v-if="step === 2">
        <div class="step-msg">
          We have sent an email with a verification code to your mailbox. Please
          enter the verification code you received. The verification code is
          valid for 30 minutes.
        </div>
        <div
          class="step-resend"
          @click="onClickResend"
          :style="time > 0 ? 'color:#A7A9AC' : '#86A1A9'"
        >
          Not received? Resend&nbsp;
          <div class="step-count-down" v-show="time > 0">({{ time }}s)</div>
        </div>
        <input
          ref="code"
          class="email"
          type="text"
          @keyup="code = code.replace(/[^\d]/g, '')"
          maxlength="6"
          v-model.trim="code"
          placeholder="Verification code"
        />
        <button class="button-confirm" @click="onClickVerify">
          Verify
        </button>
      </template>
      <template v-if="step === 3">
        <div class="step-msg">
          Please reset your login password. After the setting is successful, the
          original password will be invalid. Please use the new password to log
          in to the APP.
        </div>
        <input
          ref="pwd"
          class="pwd"
          type="password"
          v-model.trim="pwd"
          oninput="if(value.length>20)value=value.slice(0,20)"
          placeholder="New password"
          style="margin-top:50px;"
        />
        <input
          ref="pwdc"
          class="pwd"
          type="password"
          v-model.trim="pwdc"
          oninput="if(value.length>20)value=value.slice(0,20)"
          placeholder="Confirm password"
          style="margin-top:10px;"
        />
        <div v-show="confirmPasswordError" class="errormsg">
          The confirmation password does not match the password. Please
          <span @click="onClickResetIt">reset it.</span>
        </div>
        <button class="button-confirm" @click="onClickReset">
          Reset
        </button>
      </template>
    </div>
    <div class="footer"></div>
    <van-popup
      class="reset-popup"
      close-icon="close"
      round
      v-model="showResetPopup"
      :close-on-click-overlay="false"
      close-icon-position="top-right"
    >
      <div class="popup-header"></div>
      <div class="popup-content">
        <img src="@/assets/icons/icon_reset.png" alt="" />
        <div class="tips-title">Password has been reset</div>
        <div class="tips-msg">Please log in with new password</div>
      </div>
      <div class="popup-button" @click="onClickLogin">
        Log in
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Retrieve",
  data() {
    return {
      step: 1, //步骤
      email: "", //邮箱
      code: "", //验证码
      time: 0, //倒计时读秒
      pwd: "", //修改密码
      pwdc: "", //确认密码
      showResetPopup: false //密码充值成功
    };
  },
  created() {},
  computed: {
    ...mapState(["brandImage"]),
    ...mapState("theme",["themeMainColor"]),
    confirmPasswordError() {
      if (!this.$utils.isNullAndEmpty(this.pwdc) && this.pwd != this.pwdc) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    email(value) {
      if (!this.$utils.isNullAndEmpty(value)) {
        this.$refs.email.style.background = "#e8eff2";
        this.$refs.email.style.border = "none";
      }
    },
    code(value) {
      if (!this.$utils.isNullAndEmpty(value)) {
        this.$refs.code.style.background = "#e8eff2";
        this.$refs.code.style.border = "none";
      }
    },
    pwd(value) {
      if (!this.$utils.isNullAndEmpty(value)) {
        this.$refs.pwd.style.background = "#e8eff2";
        this.$refs.pwd.style.border = "none";
      }
    },
    pwdc(value) {
      if (!this.$utils.isNullAndEmpty(value)) {
        this.$refs.pwdc.style.background = "#e8eff2";
        this.$refs.pwdc.style.border = "none";
      }
    }
  },
  methods: {
    onClickReturn() {
      if (this.step === 1) {
        this.$router.go(-1);
      } else if (this.step == 3) {
        this.step = 1;
      } else {
        this.step -= 1;
      }
    },
    onClickConfirm() {
      if (this.$utils.isNullAndEmpty(this.email)) {
        this.$refs.email.style.background = "#f4e6e9";
        this.$refs.email.style.border = "1px solid #e08cae";
        return;
      } else {
        this.$refs.email.style.background = "#e8eff2";
        this.$refs.email.style.border = "none";
      }
      this.$api.User.sendEmail({
        email: this.email
      })
        .then(res => {
          if (res.code === 200) {
            this.step += 1;
            this.time = 60;
            this.countDown();
            this.$toast(res.message);
          } else {
            this.$toast(res.message);
          }
        })
        .catch(error => {
          this.$toast(error);
        });
    },
    onClickVerify() {
      if (this.$utils.isNullAndEmpty(this.code) || this.code.length < 6) {
        this.$refs.code.style.background = "#f4e6e9";
        this.$refs.code.style.border = "1px solid #e08cae";
        return;
      } else {
        this.$refs.code.style.background = "#e8eff2";
        this.$refs.code.style.border = "none";
      }
      this.$api.User.verificationCode({
        code: this.code,
        email: this.email
      })
        .then(res => {
          if (res.code === 200) {
            this.step += 1;
          } else {
            this.$toast(res.message);
          }
        })
        .catch(error => {
          this.$toast(error);
        });
    },
    onClickReset() {
      if (
        this.$utils.isNullAndEmpty(this.pwd) ||
        this.pwd.length < 6 ||
        this.pwd.length > 20
      ) {
        this.$refs.pwd.style.background = "#f4e6e9";
        this.$refs.pwd.style.border = "1px solid #e08cae";
        return;
      } else {
        this.$refs.pwd.style.background = "#e8eff2";
        this.$refs.pwd.style.border = "none";
      }
      if (
        this.$utils.isNullAndEmpty(this.pwdc) ||
        this.pwdc.length < 6 ||
        this.pwdc.length > 20
      ) {
        this.$refs.pwdc.style.background = "#f4e6e9";
        this.$refs.pwdc.style.border = "1px solid #e08cae";
        return;
      } else {
        this.$refs.pwdc.style.background = "#e8eff2";
        this.$refs.pwdc.style.border = "none";
      }
      if (this.confirmPasswordError) return;
      this.$api.User.retrievePassword({
        code: this.code,
        email: this.email,
        password: this.pwd
      })
        .then(res => {
          if (res.code === 200) {
            this.showResetPopup = true;
          } else {
            this.$toast(res.message);
          }
        })
        .catch(error => {
          this.$toast(error);
        });
    },
    onClickLogin() {
      this.$router.go(-1);
    },
    onClickResend() {
      if (this.time > 0) return;
      this.$api.User.sendEmail({
        email: this.email
      })
        .then(res => {
          if (res.code === 200) {
            this.$toast(res.message);
            this.countDown();
          } else {
            this.$toast(res.message);
          }
        })
        .catch(error => {
          this.$toast(error);
        });
    },
    //倒计时读秒
    countDown() {
      this.time = 60;
      this.timer = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    onClickResetIt() {
      this.pwdc = "";
      this.$refs.pwdc.focus();
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  .header {
    padding-top: 40px;
    margin-bottom: 60px;
    display: flex;
    align-items: center;

    .header-logo {
      margin: 0 auto;
      padding-right: 25px;
    }

    .header-return {
      width: 33px;
      height: 33px;
      margin-top: 5px;
      margin-left: 25px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    padding: 0 25px;

    .content-title {
      font-size: 18px;
      font-weight: bold;
      color: #6d6e71;
    }

    .content-step {
      display: flex;
      margin-top: 40px;
      justify-content: center;
      margin-right: 5px;

      .step-split {
        margin-top: 20px;
        width: 60px;
        height: 2px;
        background-color: var(--themeMainColor);
      }

      .step-item {
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 85px;

        img {
          width: 40px;
          height: 40px;
          margin-bottom: 10px;
        }
      }
    }
    .step-msg {
      margin-top: 60px;
      font-size: 18px;
      font-weight: 400;
      color: #231f20;
    }

    .step-resend {
      margin-top: 30px;
      font-size: 18px;
      font-weight: normal;
      color: var(--themeMainColor);
      cursor: pointer;
      display: flex;
    }

    .email {
      margin-top: 15px;
      border: 1px solid transparent;
    }
    .button-confirm {
      background: var(--themeMainColor);
      margin-top: 28px;
      transition: opacity 0.2s;
      -webkit-appearance: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .button-confirm:active {
      opacity: 0.8;
      -webkit-tap-highlight-color: transparent;
    }
    .errormsg {
      margin-top: 10px;
      font-size: 16px;
      font-weight: normal;
      color: #d66384;

      span {
        text-decoration: underline;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-user-select: none;
        -moz-user-focus: none;
        -moz-user-select: none;
      }
    }
  }
  .reset-popup {
    width: 90%;

    .popup-header {
    }

    .popup-content {
      display: flex;
      overflow-y: auto;
      flex-direction: column;
      padding: 0 30px;
      align-items: center;

      img {
        width: 110px;
        height: 110px;
        margin: 50px 0 30px 0;
      }

      .tips-title {
        font-size: 21px;
        font-weight: bold;
        color: #6d6e71;
      }

      .tips-msg {
        margin: 10px 0 60px 0;
        font-size: 18px;
        font-weight: 400;
        color: #808285;
      }
    }

    .popup-button {
      margin: 0 25px 27px 25px;
      height: 45px;
      line-height: 45px;
      background: var(--themeMainColor);
      border-radius: 8px;
      text-align: center;
      font-size: 21px;
      font-weight: bold;
      color: #ffffff;
    }
  }
}

.step-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width:40px;
  height:40px;
  border-radius: 50%;
  border: 2px solid #999999;

}

.step-container-active {
  border: 2px solid var(--themeMainColor);
}


.inner {
  width:36px;
  height:36px;
  border-radius: 50%;
  background:  #999999;
  font-size:20px;
  font-weight: bold;
  color: white;
  text-align: center;
  line-height: 36px;
}

.active {
  background: var(--themeMainColor);
}
</style>
